import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import NavbarComponent from './NavbarComponent.js';
import { Card, FormGroup } from "react-bootstrap";
import { Col, Row, Modal } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import * as ReactBootstrap from 'react-bootstrap';
import "./Navbar.css";
import "./CQ.module.css"
import { Form, FormControl, Button, Image, FormCheck, textarea } from 'react-bootstrap';
import FloatingLabel from "react-bootstrap-floating-label";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ConfirmAlert from "../../common/ConfirmAlert";
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import BootstrapTable from 'react-bootstrap-table-next';
import * as businessmoment from 'moment-business-days'
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import useUnsavedChangesWarning from "./useUnsavedChangesWarning";
import AxiosApi from "../../Api.js";
import Dropzone from 'react-dropzone-uploader';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from 'yup';
import { SettingsOverscanOutlined, Watch } from '@material-ui/icons';
import * as moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle } from "react-icons/fa";
import { Checkmark } from 'react-checkmark'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import {roles,updateRoles} from '../../Constants.js';
const { ExportCSVButton } = CSVExport;

function AddComplaint2() {
  var file_count = 0;
  let navigate = useNavigate();
  const pricesorder = [
    { id: "1", salesorder: "", container: "", POnumber: "", Qty: "", SKU: "", BatchCode: "", Dateofarrival: "", dataverified: {} },
  ];
  const pricesdata1 = [
    { id: "1", fees: "Import Taxes", cost: "0.00", currency: "EUR" },
    { id: "2", fees: "Inspection", cost: "0.00", currency: "EUR" },
    { id: "3", fees: "Reworking/Restacking", cost: "0.00", currency: "EUR" },
    { id: "4", fees: "Destruction/Disposal", cost: "0.00", currency: "EUR" },
    { id: "5", fees: "Relabelling", cost: "0.00", currency: "EUR" },
    { id: "6", fees: "Transportation", cost: "0.00", currency: "EUR" },
    { id: "7", fees: "Product Discounts", cost: "0.00", currency: "EUR" },
    { id: "8", fees: "Ocean Freight", cost: "0.00", currency: "EUR" },
    { id: "9", fees: "Customs Fee", cost: "0.00", currency: "EUR" },
    { id: "10", fees: "Demurrage & Detention/ Storage/ Waiting charges", cost: "0.00", currency: "EUR" },
    { id: "11", fees: "Other", cost: "0.00", currency: "EUR" },
  ];
  var size = 0;
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };

  const [ackno, setackno] = useState()
  const [rowvalidating, setrowvalidating] = useState(false)
  const [dropdisabled, setdropdisabled] = useState(false);
  const [parentacclist, setparentacclist] = useState([])
  const [parentaccno, setparentaccno] = useState()
  const [parentaccname, setparentaccname] = useState()
  const [currencylist, setcurrencylist] = useState([])
  const [sessionid, setsessionid, refsessionid] = useState([]);
  const [isdisabled, setisdisabled, refisdisabled] = useState(true);
  const [ifdisabled, setifdisabled] = useState(false);
  const [Prompt, setDirty] = useUnsavedChangesWarning();
  const [data1, setdata1, refdata1] = useState([...pricesdata1]);
  const [order, setorder, reforder] = useState([...pricesorder]);
  const [differentquantity, setdifferentquantity] = useState(false);
  const [isOpened, setisOpened] = useState("");
  const [fcount, setfcount, reffcount] = useState();
  const [isvalid, setisvalid, refisvalid] = useState(false);
  const [iselected, setisselected] = useState(false);
  const [autofillsalesorder, setautofillsalesorder] = useState(); //variable for autofilling sales order
  const [emailautofill, setemailauotfill] = useState();//variable for autoflling email
  const [dataverifycheck, setdataverifycheck] = useState();
  const [companydropdown, setcompanydropdown] = useState();
  const [typeofcomp, settypeofcomp, reftypeofcomp] = useState();
  const [complaintregistered, setcomplaintregistered] = useState("No");
  const [complaintregistering, setcomplaintregistering] = useState("No");
  const [del, setdel, refdel] = useState(0)
  const [filesfinal, setfilesfinal, reffilesfinal] = useState([])
  const [errorsubmitting, seterrorsubmitting] = useState(false)

  const handleUnload = (event) => {
    if (refdel.current == 0) {
      var tempemailid = (roles.username);
      AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/removefilesfromdb', { session: refsessionid.current, filenames: reffilesfinal.current, email: tempemailid }).then(response => {
        var message = response;
        console.log(message);
      })
    }
    const uploadurl2 = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/delete/api' + '/' + refsessionid.current;
    AxiosApi.post(uploadurl2).then(function (response) {
      var folder = response
      console.log(folder)
    }).catch(function (error) {
      // handle error
      console.log(error);
    })
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id').then(function (response) {
      // handle success
      var ses = response?.data;
      setsessionid(ses);
      console.log(ses);
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  };

  React.useEffect(() => {
    window.addEventListener('unload', handleUnload);

    // cleanup this component
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  // window.addEventListener('unload', function(event) {
  //   if(del == 0){
  //     AxiosApi.post('/storefileinfo/removefilesfromdb',{session:sessionid}).then(response => {
  //       var message = response ;
  //       console.log(message);
  //     })
  //   }
  //     const uploadurl2 = '/delete/api'+'/'+sessionid;
  //     AxiosApi.post(uploadurl2).then(function (response) {
  //         var folder = response
  //         console.log(folder)
  //     })
  //     AxiosApi.get('/session/api/id').then(function (response) {
  //       // handle success
  //       var ses = response?.data;
  //       setsessionid(ses);
  //       console.log(ses);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  // });
  const handleOnChange1 = () => {
    setdifferentquantity(!differentquantity);
  };
  const handlechangetype = (e) => {
    settypeofcomp(e.target.value);
    //   for(var i=0;i<reforder.current.length;i++){
    //     if((reftypeofcomp.current=="quality"))
    //     {
    //       if(reforder.current[i].salesorder!=''  && reforder.current[i].SKU!='' && reforder.current[i].Qty!='' && reforder.current[i].Dateofarrival!='' && reforder.current[i].BatchCode!=''){
    //         AxiosApi.get(`/verifyautofillfromdatabase?salesorder=${reforder.current[i].salesorder}&container=${reforder.current[i].container}&POnumber=${reforder.current[i].POnumber}&SKU=${ reforder.current[i].SKU}&BatchCode=${reforder.current[i].BatchCode}`).then(function (response)  {
    //           console.log("checking value of data error")
    //            console.log(response?.data)
    //             reforder.current[i].dataverified =  (response?.data);
    //             let temp_state =[...order];
    //             temp_state.map(a => a.id == reforder.current[i].id ? a.dataverified=reforder.current[i].dataverified : a.dataverified=a.dataverified)

    //             setorder(temp_state);
    //             console.log(reforder.current[i]);
    //             //reforder.current[i].orderbasevalidationekey={dataverifycheck};

    //             // console.log(reforder.current[i].orderbasevalidationekey)




    //         })
    //       }
    //     }
    //     else{

    //     if(reforder.current[i].salesorder!=''  && reforder.current[i].SKU!='' && reforder.current[i].Qty!='' && reforder.current[i].Dateofarrival!=''){
    // AxiosApi.get(`/verifyautofillfromdatabase?salesorder=${reforder.current[i].salesorder}&container=${reforder.current[i].container}&POnumber=${reforder.current[i].POnumber}&SKU=${ reforder.current[i].SKU}&BatchCode=${reforder.current[i].BatchCode}`).then(function (response)  {
    //         console.log("checking value of data error")
    //          console.log(response?.data)
    //           reforder.current[i].dataverified =  (response?.data);
    //           let temp_state =[...order];
    //           temp_state.map(a => a.id == reforder.current[i].id ? a.dataverified=reforder.current[i].dataverified : a.dataverified=a.dataverified)

    //           setorder(temp_state);
    //           console.log(reforder.current[i]);
    //           //reforder.current[i].orderbasevalidationekey={dataverifycheck};

    //           // console.log(reforder.current[i].orderbasevalidationekey)




    //       })
    //     }
    //   }
    // }

    var validation = false;
    for (var i = 0; i < reforder.current.length; i++) {

      if (reftypeofcomp.current == "quality" && reforder.current[i].BatchCode == '') {
        console.log("This is the text")
        console.log(isdisabled)
        console.log("This is the final text")
        console.log(watch("typeofcomplaint"))
        console.log(reforder.current[i].BatchCode)
        setisvalid(false);
        validation = false;
        setisdisabled(true)
        break;
      }
      if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || refdata1.current[i].cost == '' || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
        setisvalid(false);
        validation = false;
        setisdisabled(true)
        break;
      }
      else {
        setisvalid(true);
        validation = true;
        console.log(validation);
      }
      if (reforder.current[i].BatchCode != '' && reftypeofcomp.current == "quality" && reforder.current[i].dataverified.batchcodeverify == "false") {
        setisvalid(false);
        validation = false;
        setisdisabled(true)
        break;

      }

    }
    if (reffcount.current > 0 && validation == true) {
      setisdisabled(false)
    }
    else {
      setisdisabled(true)
    }
  }
  const showbox = (e) => {
    console.log("Hello")
    var amt = e.target.value

    if (parseInt(amt) < 1000) {
      setisOpened("true");

    }
    else {
      setisOpened("false");
    }
  };
  const orders = action => {
    if (!action) {
      return order;
    } else {
      switch (action.actionType) {
        case "addRow":
          let newRow = {};
          newRow.id = reforder.current.length + 1;
          newRow.salesorder = '';
          newRow.container = '';
          newRow.POnumber = '';
          newRow.Qty = '';
          newRow.SKU = '';
          newRow.BatchCode = '';
          newRow.Dateofarrival = '';
          newRow.dataverified = {};
          setorder([...order, newRow]);
          console.log("addrow")
          return order;
        case "deleteRow":
          //this delets different rows only
          let new_state = order.filter(
            row => row.id !== action.row || row.salesorder !== action.salesorder
          );
          console.log("deleterow")
          setorder([...new_state]);
          return order;
        default:
          return order;
      }
    }
  };
  const prices = action => {
    if (!action) {
      return data1;
    } else {
      switch (action.actionType) {
        case "addRow":
          let newRow = {};
          newRow.id = data1.length + 1;
          newRow.salesorder = " ";
          newRow.container = " ";
          newRow.POnumber = " ";
          newRow.Qty = " ";
          newRow.SKU = " ";
          newRow.BatchCode = " ";
          newRow.Dateofarrival = " ";
          newRow.dataverified = {};
          setdata1([...data1, newRow]);
          console.log("addrow")
          return data1;
        case "deleteRow":
          //this delets different rows only
          let new_state = data1.filter(
            row => row.id !== action.row || row.salesorder !== action.salesorder
          );
          console.log("deleterow")
          setdata1([...new_state]);
          return data1;
        default:
          return data1;
      }
    }
  };
  const changeHidden = () => {
    const newColumns = data1.columns.map((column) => {
      if (column.dataField !== 'id') return column;
      return {
        ...column,
        hidden: !column.hidden
      };
    });
    setdata1({ columns: newColumns });
  }
  const columns = [{
    dataField: "id",
    text: "Id",
    hidden: true
  },
  {
    dataField: "fees",
    text: "Extra fees type",
    editable: false,
  },
  {
    dataField: "cost",
    text: "Cost",
    validator: (newValue, row, column) => {
      if (isNaN(newValue)) {
        setisdisabled(true);
        row.cost = ''
        return {
          valid: false,
          message: 'Cost should be numeric'
        };
      }
      if (newValue == '') {
        setisdisabled(true);
        row.cost = ''
        return {
          valid: false,
          message: 'Cost is required'
        };
      }
      return true;
    }
  },
  {
    dataField: "currency",
    text: "Currency",
    editor: {
      type: Type.SELECT,
      options: [{
        value: 'EUR',
        label: '▼EUR'
      }, {
        value: 'USD',
        label: 'USD'
      }, {
        value: 'GBP',
        label: 'GBP'
      }, {
        value: 'MXN',
        label: 'MXN'
      }, {
        value: 'AED',
        label: 'AED'
      }, {
        value: 'AOA',
        label: 'AOA'
      }, {
        value: 'ARS',
        label: 'ARS'
      }, {
        value: 'AUD',
        label: 'AUD'
      }, {
        value: 'BAM',
        label: 'BAM'
      }, {
        value: 'BBD',
        label: 'BBD'
      }, {
        value: 'BGN',
        label: 'BGN'
      }, {
        value: 'BHD',
        label: 'BHD'
      }, {
        value: 'BOB',
        label: 'BOB'
      }, {
        value: 'BRL',
        label: 'BRL'
      }, {
        value: 'BWP',
        label: 'BWP'
      }, {
        value: 'BYN',
        label: 'BYN'
      }, {
        value: 'CAD',
        label: 'CAD'
      }, {
        value: 'CHF',
        label: 'CHF'
      }, {
        value: 'CLP',
        label: 'CLP'
      }, {
        value: 'CNY',
        label: 'CNY'
      }, {
        value: 'COP',
        label: 'COP'
      }, {
        value: 'CRC',
        label: 'CZK'
      }, {
        value: 'DKK',
        label: 'DKK'
      }, {
        value: 'DJF',
        label: 'DJF'
      }, {
        value: 'DOP',
        label: 'DOP'
      }, {
        value: 'DZD',
        label: 'DZD'
      }, {
        value: 'ETB',
        label: 'ETB'
      }, {
        value: 'XCD',
        label: 'XCD'
      }, {
        value: 'GHS',
        label: 'GHS'
      }, {
        value: 'GTQ',
        label: 'GTQ'
      }, {
        value: 'HKD',
        label: 'HKD'
      }, {
        value: 'HNL',
        label: 'HNL'
      }, {
        value: 'HRK',
        label: 'HRK'
      }, {
        value: 'HUF',
        label: 'HUF'
      }, {
        value: 'INR',
        label: 'INR'
      }, {
        value: 'ISK',
        label: 'ISK'
      }, {
        value: 'ILS',
        label: 'ILS'
      }, {
        value: 'JPY',
        label: 'JPY'
      }, {
        value: 'KES',
        label: 'KES'
      }, {
        value: 'KRW',
        label: 'KRW'
      }, {
        value: 'KZT',
        label: 'KZT'
      }, {
        value: 'LSL',
        label: 'LSL'
      }, {
        value: 'MAD',
        label: 'MAD'
      }, {
        value: 'MUR',
        label: 'MUR'
      }, {
        value: 'MWK',
        label: 'MWK'
      }, {
        value: 'MYR',
        label: 'MYR'
      }, {
        value: 'MZN',
        label: 'MZN'
      }, {
        value: 'NAD',
        label: 'NAD'
      }, {
        value: 'NGN',
        label: 'NGN'
      }, {
        value: 'NOK',
        label: 'NOK'
      }, {
        value: 'NZD',
        label: 'NZD'
      }, {
        value: 'OMR',
        label: 'OMR'
      }, {
        value: 'PEN',
        label: 'PEN'
      }, {
        value: 'PHP',
        label: 'PHP'
      }, {
        value: 'PLN',
        label: 'PLN'
      }, {
        value: 'RON',
        label: 'RON'
      }, {
        value: 'RSD',
        label: 'RSD'
      }, {
        value: 'RUB',
        label: 'RUB'
      }, {
        value: 'SAR',
        label: 'SAR'
      }, {
        value: 'SDG',
        label: 'SDG'
      }, {
        value: 'SEK',
        label: 'SEK'
      }, {
        value: 'SGD',
        label: 'SGD'
      }, {
        value: 'SSP',
        label: 'SSP'
      }, {
        value: 'SZL',
        label: 'SZL'
      }, {
        value: 'THB',
        label: 'THB'
      }, {
        value: 'TRY',
        label: 'TRY'
      }, {
        value: 'TZS',
        label: 'TZS'
      }, {
        value: 'UAH',
        label: 'UAH'
      }, {
        value: 'UGX',
        label: 'UGX'
      }, {
        value: 'UYU',
        label: 'UYU'
      }, {
        value: 'VEF',
        label: 'VEF'
      }, {
        value: 'VND',
        label: 'VND'
      }, {
        value: 'ZAR',
        label: 'ZAR'
      }, {
        value: 'ZMW',
        label: 'ZMW'
      },]
    },
    validator: (newValue, row, column) => {
      if (newValue == '') {
        return {
          valid: false,
          message: 'All currencies are required'
        };
      }
      return true;
    }
  },
  ];
  useEffect(() => {
    AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/parent/api/getparentdetails', { emailid: (roles.username) }).then(function (response) {
      // handle success
      console.log(response?.data, 'parentdetails')
      setparentacclist(response?.data)
      setparentaccno(response?.data[0]?.accountnumber)
      setparentaccname(response?.data[0]?.accountname)
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, [])

  useEffect(() => {
    var role = (roles.role)
    var emailid1 = (roles.username);
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchcompanydropdown?emailid1=${emailid1}&role=${role}`)
      .then((response) => {
        console.log(response?.data, "company array")
        setcompanydropdown(response?.data);
        // setorder(...order,)
      })


  }, []);

  useEffect(() => {
    var emailautofill = (roles.username);
    console.log(emailautofill)
    setemailauotfill(emailautofill)


  }, []);
  useEffect(() => {
    console.log("Getting session id");
    AxiosApi.get(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/session/api/id').then(function (response) {
      // handle success
      var ses = response?.data;
      setsessionid(ses);
      console.log(ses);

      // setValue("companyname", '', { shouldDirty: true });
      setValue('complaintdesc', '', { shouldDirty: true });
      setValue('typeofcomplaint', '', { shouldDirty: true });
      setValue('email', emailautofill, { shouldDirty: true });
      setValue('totalamt', '0', { shouldDirty: true });
      setValue('totaldecimal', '00', { shouldDirty: true });
      setValue('currency', '', { shouldDirty: true });
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }, []);

  async function hideAlert() {
    window.location.reload();
  }

  const getUploadParams = ({ meta, file }) => {
    console.log(meta);
    const body = new FormData()
    //body.append('sessionid', sessionid);
    body.append('customFile', file);
    const uploadurl = process.env.REACT_APP_API_ROOT_URL_PREFIX + '/files/api/files-1' + '/' + sessionid;
    console.log(uploadurl)
    return { url: uploadurl, body }
  }
  const validationSchema = Yup.object().shape({
    // companyname: Yup.string()
    //     .required('Company Name is required'),
    typeofcomplaint: Yup.string()
      .required('Type of Complaint is required'),
    complaintdesc: Yup.string()
      .required('Complaint Description is required'),

    // email: Yup.string()
    //     .required('Email is required')
    //     .email('Email is invalid'),
    totalamt: Yup.string()
      .required('Total Cost is required'),
    totaldecimal: Yup.string()
      .required('Total Cost Decimal is required'),
    currency: Yup.string()
      .required('Currency is required')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, watch, setValue, handleSubmit, reset, formState } = useForm({ resolver: yupResolver(validationSchema) });
  const { errors } = formState;

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChangeStatus = async ({ meta, file }, status) => {
    setDirty();
    console.log(status, meta, file);
    console.log("hello jatin")
    if (status == "rejected_file_type") {
      alert("UnSupported file type. Either the extension is not supported / your file size is > than 50 MB")
    }
    if (status == 'error_upload') {
      alert("File upload failed, files being uploaded may be open in your local PC")
    }
    if (status == 'done') {

      {
        var bs64 = await convertBase64(file)
        console.log("starting file information logging to backend", file);
        console.log("file meta info print", meta)
        await AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/storedetails', { meta: meta, file: file, bs64: bs64, session: sessionid, email: emailautofill });
      }
      setfilesfinal(filesfinal => [...filesfinal, meta.name]);
      size += file.size;
      if (size > 200000000) {
        setisdisabled(true);
      }
      else if (size < 200000000) {
        setisdisabled(false)
      }
      console.log("The total files size after upload is:")
      console.log(size);
      console.log("This is the current number:")
      AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/count/api/filecount/${sessionid}`).then(function (response) {
        console.log("Hello files")
        console.log(response?.data)
        file_count = response?.data
        setfcount(response?.data)
        if (file_count > 0 && refisvalid.current == true) {
          setisdisabled(false)
        }
        else {
          setisdisabled(true)
        }
      })
    }
    if (status == 'removed') {
      var filename = file.name
      var tempemailid = (roles.username);
      {
        AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/storefileinfo/removefilefromdb', { meta: meta, file: file, session: sessionid, email: tempemailid }).then(response => {
          var message = response;
          console.log(message);
        });
      }
      AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/remove/api/${sessionid}`, { fname: filename }).then(response => {
        var message = response;
        console.log(`Removed file message:`)
        console.log(message);
      }).then(
        AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/folder/api/folder/${sessionid}`).then(response => {
          size = response?.data.filesize;
          console.log(size);
        }))
      if (size > 200000000) {
        setisdisabled(true);
      }
      else if (size < 200000000) {
        setisdisabled(false)
      }
      console.log(size);
      AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/count/api/filecount/${sessionid}`).then(function (response) {
        console.log("This is the current number:")
        console.log(response?.data)
        console.log("This is the final number")
        file_count = response?.data
        setfcount(response?.data)
        for (var i = 0; i < reforder.current.length; i++) {
          if ((reftypeofcomp.current == "quality")) {
            if (reforder.current[i].salesorder != '' && reforder.current[i].SKU != '' && reforder.current[i].Qty != '' && reforder.current[i].Dateofarrival != '' && reforder.current[i].BatchCode != '') {
              AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/verifyautofillfromdatabase?salesorder=${reforder.current[i].salesorder}&container=${reforder.current[i].container}&POnumber=${reforder.current[i].POnumber}&SKU=${reforder.current[i].SKU}&BatchCode=${reforder.current[i].BatchCode}`).then(function (response) {
                console.log("checking value of data error")
                console.log(response?.data)
                reforder.current[i].dataverified = (response?.data);
                let temp_state = [...order];
                temp_state.map(a => a.id == reforder.current[i].id ? a.dataverified = reforder.current[i].dataverified : a.dataverified = a.dataverified)

                setorder(temp_state);
                console.log(reforder.current[i]);
                //reforder.current[i].orderbasevalidationekey={dataverifycheck};

                // console.log(reforder.current[i].orderbasevalidationekey)




              })
            }
          }
          else {

            if (reforder.current[i].salesorder != '' && reforder.current[i].SKU != '' && reforder.current[i].Qty != '' && reforder.current[i].Dateofarrival != '') {
              AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/verifyautofillfromdatabase?salesorder=${reforder.current[i].salesorder}&container=${reforder.current[i].container}&POnumber=${reforder.current[i].POnumber}&SKU=${reforder.current[i].SKU}&BatchCode=${reforder.current[i].BatchCode}`).then(function (response) {
                console.log("checking value of data error")
                console.log(response?.data)
                reforder.current[i].dataverified = (response?.data);
                let temp_state = [...order];
                temp_state.map(a => a.id == reforder.current[i].id ? a.dataverified = reforder.current[i].dataverified : a.dataverified = a.dataverified)

                setorder(temp_state);
                console.log(reforder.current[i]);
                //reforder.current[i].orderbasevalidationekey={dataverifycheck};

                // console.log(reforder.current[i].orderbasevalidationekey)




              })
            }
          }
        }
        var validation = false;
        for (var i = 0; i < reforder.current.length; i++) {
          if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || refdata1.current[i].cost == '' || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
            setisvalid(false);
            validation = false;
            setisdisabled(true)
            break;
          }
          else {
            setisvalid(true);
            validation = true;
            console.log(validation);
          }
          if (reforder.current[i].BatchCode == '' && (reftypeofcomp.current == "quality") && reforder.current[i].dataverified.batchcodeverify == "false") {
            setisvalid(false);
            validation = false;
            setisdisabled(true)
            break;

          }
          if (reftypeofcomp.current == "quality" && reforder.current[i].BatchCode == '') {
            setisvalid(false);
            validation = false;
            setisdisabled(true)
            break;
          }
        }
        if (file_count > 0 && refisvalid.current == true) {
          setisdisabled(false)
        }
        else {
          setisdisabled(true)
        }
      })
    }
    if (status == 'error_validation') {
      alert("Maximum size is 200000000MB totally");
    }
  }

  const handleSubmit2 = (files, allFiles) => {

    console.log("handleSubmit %o", files)

    for (var i = 0; i < files.length; i++) {
      console.log("meta: %o", files[i].meta)
      size += files[i].meta.size;
    }

    console.log("size: ", size);
    allFiles.forEach(f => f.remove())
  }

  if ((roles.username)?.length > 0) {
    if ((roles.role) != 'External_Customer' && (roles.role) != 'Internal_Customer' && (roles.role) != 'ABI_CQTeam') {

      return (
        <h1>You are not authorized to view this page</h1>
      );
    }
  }

  const getFilesFromEvent = e => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
        resolve(chosenFiles.map(f => f.fileObject))
      })
    })
  }
  const validatefile = ({ meta, file }) => {
    const valsize = 200000000;
    var totsize = size + file.size
    if ((totsize) > valsize) {
      return ("File Upload Error: Maximum total files size = 200 MB")
    }
    else {
      return (false)
    }
  }
  const players = [
    { position: "Forward", name: "Lebron", team: "lakers" },
    { position: "Guard", name: "Russell Westbrook", team: "Rockets" },

  ]
  function onFinalSubmit(data) {
    if ((roles.role) === 'Internal_Customer' || (roles.role) === 'External_Customer') {
      console.log(data);
      navigate('/complaint-2/num/' + data);
    }
    else {
      // console.log('navigation2');
      console.log(data);
      const url = '/complaint/num/' + data;
      navigate(url);
    }
  }
  function onSubmit2(data) {
    setisdisabled(true);

    var emailautofill = (roles.username);
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/filecheck/api/${sessionid}?email=${emailautofill}`)
      .then(function (response2) {
        var filecheck = response2.data
        console.log('filecheck')
        AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/count/api/filecount/${sessionid}`).then(function (response) {
          console.log('Filecheck:', filecheck)
          if (response.data > 0 && refisvalid.current == true && filecheck == "notexceededlimit") {
            setdel(1);
            setcomplaintregistering("Yes");
            // display form data1 on success

            var complaintdetails = JSON.stringify(data, null, 4)
            var finalcomplaint = JSON.parse(complaintdetails)
            var complaintdata = { detail: finalcomplaint, orderdet: { order }, feesdet: { data1 } }
            console.log(complaintdata)
            AxiosApi.post(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/addcomplaint/${sessionid}`, { finaldata: complaintdata, complaintby: (roles.role), parentno: parentaccno, parentname: parentaccname, emailid: emailautofill })
              .then(function (response) {
                console.log("Hello files")
                // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4) + order + data1);
                console.log(response?.data)
                setackno(parseInt(response?.data))
                setcomplaintregistered("Yes");
                setShowModal(true);
              })
          }
          else {
            seterrorsubmitting(true)
          }
        })
      })
    return false;
  }

  const handleautofillsalesorder = () => {
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/salesautofill/salesorderautofilltable1?salesordervalue=${autofillsalesorder}`)
      .then((response) => {
        console.log(response?.data.recordset)

        // setorder(...order,)
      })
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/salesautofill/salesorderautofilltable2?salesordervalue=${autofillsalesorder}`)
      .then((response) => {
        console.log(response?.data.recordset)

        //   Object.keys(obj).forEach((key) => {
        //     var replacedKey = key.trim().toUpperCase().replace(/\s\s+/g, "_");
        //     if (key !== replacedKey) {
        //        obj[replacedKey] = obj[key];
        //        delete obj[key];
        //     }
        //  });

        //  const transformed = response?.data.recordset.map(({ ["Container Number"] }) => ({ label: id, value: name }));
      })



    console.log(autofillsalesorder)
  }
  return (
    <div>
      <NavbarComponent />
      <Container>
        <div className="page-header">
          <h1 className="page-title">Add new complaint</h1>
        </div>
        <hr>
        </hr>
        <Form onSubmit={handleSubmit(onSubmit2)}>
          <div className="row col-lg-12">
            <div className="row">
              <div className="col-lg-4 pr-4">
                {/* <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                        <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{padddingTop:'15px'}} >
                            <Form.Label>Company Name</Form.Label>
                        </Col>
                        <Col className="text-left">
                                <Form.Control type="name" name="companyname" placeholder="Company Name"{...register('companyname')} className={`form-control ${errors.companyname ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.companyname?.message}</div>
                        </Col>                      
                         <Col className="text-left"> 
                        <select className="form-select form-select-sm select-size"  aria-label=".form-select-sm example" {...register('companyname')} className={`form-control ${errors.companyname ? 'is-invalid' : ''}`}>
                        <option value="">Select</option>
                        {companydropdown?.map((company) => 
                        <option value={company}>{company}</option>
                        )}                      
                      </select>
                      <div className="invalid-feedback">{errors.companyname?.message}</div>
                        </Col> 
                  </Form.Group> */}
                {/* <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                        <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{padddingTop:'15px'}}>
                            <Form.Label>Contact Person</Form.Label>
                        </Col>
                        <Col className="text-left">
                            <FloatingLabel controlId="floatingInputGrid" label="Contact Person">
                                <Form.Control type="name" placeholder="Contact Person" />
                            </FloatingLabel>
                        </Col>                      
                  </Form.Group> */}
                <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                  <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                    <Form.Label>Email</Form.Label>
                  </Col>
                  <Col className="text-leftcol-lg-2" style={{ padddingTop: '15px' }}>
                    {/* changed placeholder to autofill eamil  
                          {...register('email')}*/}
                    <Form.Control type="name" editable={false} name="email" value={emailautofill} placeholder={emailautofill} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </Col>
                </Form.Group>
                {(parentacclist?.length > 1 && (roles.role) == "Internal_Customer") && (
                  <>
                    <Form.Group as={Row} className="mb-2 group-box" controlId="formHorizontalEmail">
                      <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                        <Form.Label>Parent Account:<a data-tip data-for='parentaaccount' style={{ color: "#0d6efd", fontWeight: "700" }}> <span className='text-right'><FaInfoCircle /></span> </a>
                          <Tooltip place="right" id='parentaccount' aria-haspopup='true' effect="solid" >
                            <p style={{ textAlign: 'left' }}>Parent Account:</p>
                            <p style={{ textAlign: 'left' }}>Please select the appropriate parent account, to which you are raising the complaint for.<br /></p>
                          </Tooltip></Form.Label>
                      </Col>
                      <Col className="text-left">
                        <select aria-label=".form-select-sm example" disabled={dropdisabled}
                          onChange={e => { setparentaccno(e.target.value); setparentaccname(e.target.name); }}
                          className={`form-control form-select form-select-sm select-size ${errors.typeofcomplaint ? 'is-invalid' : ''}`}>
                          {parentacclist?.map((parentacclist) =>
                            <option value={parentacclist?.accountnumber} name={parentacclist?.accountname}>{parentacclist?.accountname}</option>
                          )}
                        </select>
                      </Col>
                    </Form.Group>
                  </>
                )}
              </div>
              <div className="col-lg-4 pr-4">
                <Form.Group as={Row} className="mb-2 group-box" controlId="formHorizontalEmail">
                  <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                    <Form.Label>Type of Complaint <a data-tip data-for='typeofcomplaint' style={{ color: "#0d6efd", fontWeight: "700" }}> <span className='text-right'><FaInfoCircle /></span> </a>
                      <Tooltip place="right" id='typeofcomplaint' aria-haspopup='true' effect="solid" >
                        <p style={{ textAlign: 'left' }}>Type of Complaint:</p>
                        <p style={{ textAlign: 'left' }}>Quality Complaints: sour taste, flat, fobbing, abnormal smell,<br />
                          missing/damaged labels, incorrect labels, incorrect or rusty<br />
                          crowns (bottles), missing information on the labels/caps<br />
                          (bottles/kegs), leaking kegs, empty kegs/incomplete filling<br />
                          with intact caps, issues with the kegs seal, etc</p>
                        <p style={{ textAlign: 'left' }}>Logistics Complaints: missing items, incorrectly delivered items,<br /> short shelf life, damaged items during transport/loading,<br /> incorrect data or information on the transport documents  or<br />  product  invoices,  documents  not  received  on  time,  demurrage  charges.</p>
                      </Tooltip></Form.Label>
                  </Col>
                  <Col className="text-left">
                    <select aria-label=".form-select-sm example" {...register('typeofcomplaint')}
                      disabled={dropdisabled} onChange={e => { settypeofcomp(e.target.value); handlechangetype(e) }}
                      className={`form-control form-select form-select-sm select-size ${errors.typeofcomplaint ? 'is-invalid' : ''}`}>
                      <option value="">Select</option>
                      <option value="logistics">Logistics</option>
                      <option value="quality">Quality</option>
                    </select>
                    <div className="invalid-feedback">{errors.typeofcomplaint?.message}</div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                  <Col className="boxes fields text-left col-lg-5 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                    <Form.Label>Complaint Description <a data-tip data-for='complaintdescription' style={{ color: "#0d6efd", fontWeight: "700" }}> <span className='text-right'><FaInfoCircle /></span> </a>
                      <Tooltip place="right" id='complaintdescription' aria-haspopup='true' effect="solid" >
                        <p style={{ textAlign: 'left' }}>Complaint Description:</p>
                        <p style={{ textAlign: 'left' }}>Add detailed issue description </p></Tooltip></Form.Label>
                  </Col>
                  <Col className="text-left">
                    <Form.Control as="textarea" rows={4} cols={4} {...register('complaintdesc')} onKeyPress={(event) => { if (/'/.test(event.key)) { event.preventDefault(); } }} className={`form-control ${errors.complaintdesc ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.complaintdesc?.message}</div>
                  </Col>
                </Form.Group>

              </div>
              <div className="col-lg-4 pr-4 ">
                <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                  <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                    <Form.Label >Total Product Cost <a data-tip data-for='totalproductcost' style={{ color: "#0d6efd", fontWeight: "700" }}> <span className='text-right'><FaInfoCircle /></span> </a>
                      <Tooltip place="right" id='totalproductcost' aria-haspopup='true' effect="solid" >
                        <p style={{ textAlign: 'left' }}>Total Product Cost:</p>
                        <p style={{ textAlign: 'left' }}>Product cost = Beer price</p>
                        <ul>
                          <li> 1. Add the total beer (product) price claimed<br /> Note: Enter decimal values in the decimal value box separately.​</li><br />
                          <li> 2. Choose the currency from  the drop-down list​ provided separately<br /></li>
                        </ul>
                      </Tooltip></Form.Label>
                  </Col>
                  <Col >
                    <div style={{ display: 'flex' }}>
                      <div style={{ flex: 4 }}>
                        <Form.Control type="number" name="totalamt" min="0" pattern="[0-9]" placeholder="Total Cost"  {...register('totalamt')} onChange={showbox} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} className={`form-control ${errors.totalamt ? 'is-invalid' : ''}`} />

                        <div className="invalid-feedback">{errors.totalamt?.message}</div>
                      </div>
                      <div style={{ flex: 1, width: '20%', paddingTop: '1rem' }}><strong>•</strong>
                      </div>
                      <div style={{ flex: 3 }}>

                        <input type="number" placeholder="decimal" maxlength="2" step="1" {...register('totaldecimal')} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} min="0" max="99"
                          className={`form-control orderUnits ${errors.totaldecimal ? 'is-invalid' : ''}`} />
                        <div className="invalid-feedback">{errors.totaldecimal?.message}</div>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                  <Col className="boxes fields text-left col-lg-4 pr-0 pt-2" style={{ padddingTop: '15px' }}>
                    <Form.Label>Currency</Form.Label>
                  </Col>
                  <Col className="text-left">
                    <select aria-label=".form-select-sm example" {...register('currency')}
                      className={`form-control form-select form-select-sm select-size ${errors.currency ? 'is-invalid' : ''}`}>
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                      <option value="MXN">MXN</option>
                      <option value="CNY">CNY</option>
                    </select>
                    <div className="invalid-feedback">{errors.currency?.message}</div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3 group-box" controlId="formHorizontalEmail">
                  {isOpened === "true" && (
                    <>
                      <Form.Label>Did you receive a different quantity or SKU?</Form.Label>
                      <input type="checkbox" {...register('differentquantity')} />
                    </>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
          <hr></hr>

          <div className="row" style={{ margin: "0 !important" }}>
            <div className="row col-lg-8">
              <div className="margin-table-1" style={{ maxWidth: "97%" }}>
                <div className="dz-default dz-message">
                  <span>Drop files here or click to upload. Total maximum size allowed is 200MB. Max size per file is 50MB.</span>
                </div>
                <Dropzone
                  accept="image/png,image/jpeg,application/pdf,video/mp4,video/mpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/zip,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,.msg"
                  onSuybmit={handleSubmit2}
                  validate={validatefile}
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  getFilesFromEvent={getFilesFromEvent}
                  submitButtonDisabled={isdisabled}
                  maxSizeBytes={50000000}
                  styles={{
                    dropzone: { height: 250 },
                    dropzoneActive: { borderColor: 'green', backgroundColor: '#baffb3' },
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                  }}
                />
                <div><ol>
                  <li>Strictly allowed File types :<b> .zip, .png , .jpeg, .jpg, .mp4, .mpeg, .doc, .docx, .ppt, .xls, .xlsx, .csv</b></li>
                  <li>The required fields are: Company Name, Type of complaint, Total product cost, Currency, Complaint Description</li>
                  <li>Please ensure the required values are fillled before filling the table data.</li>
                  <li>Please ensure to delete any empty rows before submitting a complaint</li>
                  <li>Both the tables are also required</li>
                  <li>Uploading files is mandatory to submit the complaint.</li>
                  <li>You have maximum of 1 hour to submit the complaint after file upload</li>
                  <li>CHECK THIS MANUAL “Defects map” TO PROVIDE CORRECT EVIDENCE FOR YOUR COMPLAINT - https://sway.cloud.microsoft/x2CkDxOju0BgjxBu?ref=Link</li>
                </ol></div>
                <div style={{ overflow: "scroll", maxWidth: "100%" }}>
                  <RenderExpenseTable order={order} orders={orders} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button className="form-group btn btn-warning" type="submit" style={{ margin: "2rem" }} disabled={refisdisabled.current}>Submit Complaint</Button>
                </div>
                {complaintregistering === "Yes" && (
                  <div>
                    <ReactBootstrap.Spinner animation="border" /> Complaint Registering...
                  </div>
                )}
                {rowvalidating === true && (
                  <div>
                    Getting Salesorder details to verify...<ReactBootstrap.Spinner animation="border" />
                  </div>
                )}
              </div>
            </div>
            <div className="row col-lg-4" style={{ overflow: "scroll" }}>
              <RenderExpenseTable2 data1={data1} prices={prices} />
            </div>
          </div>
        </Form>
      </Container>
      {errorsubmitting && (
        <ConfirmAlert
          confirm={hideAlert}
          hideAlert={hideAlert}
          cancelButton={false}
          confirmBtnText="OK"
          type={"error"}
          title={"Error Timelimit Exceeded"}
          body={
            "Please resubmit the complaint details as timelimit of 1 hour has been exceeded."
          }
        />
      )}
      {/* <SweetAlert success title="Complaint Submitted" onConfirm={onFinalSubmit(ackno)} /> */}
      {/* {complaintregistered === "Yes" && (
        Swal.fire({
          icon: 'success',
          title: 'Complaint Submitted',
          showConfirmButton: true,
          confirmButtonText: 'OK',
        }).then(() => {
          onFinalSubmit(ackno);
        })
      )} */}

      {complaintregistered === "Yes" && (
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Complaint Submitted</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Your Acknowledgment Number: {ackno.toString()}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onFinalSubmit(ackno.toString())}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}

    </div>
  )
  function RenderExpenseTable(props) {
    const { item, id } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleverficationfromdatabase = (salesorder,
      container,
      POnumber,
      SKU) => {
      console.log("function called of verify", salesorder, container, POnumber);
      AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/verifyautofillfromdatabase?salesorder=${salesorder}&container=${container}&POnumber=${POnumber}&SKU=${SKU}&&BatchCode=''`).then(function (response) {


        console.log(response?.data, "true false");
        return response?.data
      })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
    };

    function validateExtraFees() {
      for (var i = 0; i < refdata1.current.length; i++) {
        if (refdata1.current[i].cost == '') {
          return (false)
        }
        return (true)
      }
    }

    const toggle = () => setTooltipOpen(!tooltipOpen);
    useEffect(() => {
      if (!reforder.current.length) {
        setorder([...props.orders({ action: "order" })]);
      }
    }, [])

    let tableData2 = order;
    if (JSON.stringify(props.order) === JSON.stringify(tableData2)) {
      console.log("in rendered table components the new order is: updated ");
    } else {
      console.log("in rendered table components the new order is: not updated ");
      tableData2 = props.order;
    }
    const columns2 = [
      {
        dataField: "id",
        text: "Id",
        hidden: true
      },
      {
        dataField: "salesorder",
        text: "Sales Order",
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a data-tip data-for='salesorder' style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }}> SALESORDER </a>
              <Tooltip place="top" id='salesorder' aria-haspopup='true' effect="solid" >
                <p style={{ textAlign: 'left' }}>Example of Sales order  :   14092454</p>
                <p style={{ textAlign: 'left' }}>Note:</p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Always starting with 1... And has 8 digits</li>
                  <li>Sales order can be found in the Buyco report</li>
                </ol>
              </Tooltip>
            </div>
          );
        },
        validator: (newValue, row, column) => {
          var testchars;
          let spChars = /[!@#$%^&*() _+\-=\[\]{};':"\\|,.<>\/?_)]+/;
          if (spChars.test(newValue)) {
            testchars = true;
          } else {
            testchars = false;
            setisdisabled(true);
            row.salesorder = ''
          }
          if (newValue == '') {
            setisdisabled(true);
            row.salesorder = ''
            toast.error('Sales Order is required', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'Sales Order is required'
            };
          };
          if (newValue.length > 8 || newValue.length < 8) {
            setisdisabled(true);
            row.salesorder = ''
            toast.error('Sales Order must be of 8 digits', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {

              valid: false,
              message: 'Sales Order must be of 8 digits'
            };
          };
          if (testchars == true) {
            setisdisabled(true);
            row.salesorder = ''
            toast.error('Sales Order should not contain spaces or special characters', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'Sales Order should not contain spaces or special characters'
            };
          };
          return true;
        }
      },
      {
        dataField: "container",
        text: "Container",
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a data-tip data-for='container' style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }}> CONTAINER </a>
              <Tooltip place="top" id='container' aria-haspopup='true' effect="solid" >
                <p style={{ textAlign: 'left' }}>Example of Container Number : MSKU5456215</p>
                <p style={{ textAlign: 'left' }}>Note:</p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Please type without any spaces, or symbols</li>
                  <li>In case its a truck please provide the precise number, it will depend on delivery company</li>
                </ol>
              </Tooltip>
            </div>
          );
        },
        validator: (newValue, row, column) => {

          if (newValue.length > 45) {
            setisdisabled(true);
            row.salesorder = ''
            toast.error('Container can contain atmost 45 characters', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {

              valid: false,
              message: 'Container number can contain atmost 45 characters'
            };
          };

        },
      },
      {
        dataField: "POnumber",
        text: "PO Number",
        hidden: true,
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a data-tip data-for='ponumber' style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }}> PO NUMBER </a>
              <Tooltip id='ponumber' aria-haspopup='true' effect="solid" >
                <p style={{ textAlign: 'left' }}>Please provide Customer Purchase order</p>
                <p style={{ textAlign: 'left' }}>Note:</p>
                <ol style={{ textAlign: 'left' }}>
                  <li>Provide the same Purchase order that you used on the order request</li>
                  <li>You can always find it in the order acknowledgement</li>
                </ol>
              </Tooltip>
            </div>
          );
        }
      },
      {
        dataField: "Qty",
        text: "Quantity",
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a data-tip data-for='qty' style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }}> QTY </a>
              <Tooltip id='qty' aria-haspopup='true' effect="solid" >
                <p style={{ textAlign: 'left' }}>Please provide here the quantity of your SKU- Enter ONLY NUMBERS  (number Cases or number of Kegs affected).</p>
                <p style={{ textAlign: 'left' }}>Example : 1 = Keg or case</p>
                <p style={{ textAlign: 'left' }}>Note: Enter number of cases or number of kegs- not number of bottles/cans.</p>
              </Tooltip>
            </div>
          );
        },
        validator: (newValue, row, column) => {
          if (newValue == '') {
            setisdisabled(true);
            row.Qty = ''
            toast.error('Quantity is required', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'Quantity is required'
            };
          };
          if (isNaN(newValue)) {
            setisdisabled(true);
            row.Qty = ''
            toast.error('Quantity should be numeric', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'Quantity should be numeric'
            };
          }
          return true;
        }
      },
      {
        dataField: "SKU",
        text: "SKU",
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }} data-tip="Please Provide the ABI reference visible in the order portal"> SKU </a>
              <Tooltip place="top" type="dark" effect="solid" />
            </div>
          );
        },
        validator: (newValue, row, column) => {
          if (newValue == '') {
            setisdisabled(true);
            row.SKU = ''
            toast.error('SKU number is required', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'SKU number is required'
            };
          };
          if (isNaN(newValue)) {
            setisdisabled(true);
            row.SKU = ''
            toast.error('SKU number should be numeric', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'SKU number should be numeric'
            };
          }
          return true;
        }
      },
      {
        dataField: "BatchCode",
        text: "Batch Code",

        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a data-tip data-for='batchcode' style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }}> BATCH CODE </a>
              <Tooltip id='batchcode' aria-haspopup='true' effect="solid" >
                <p style={{ textAlign: 'left' }}>Only required if you have a Quality Issue.</p>
                <p style={{ textAlign: 'left' }}>Note: (refer to keg, cap, bottom or label)</p>
              </Tooltip>
            </div>
          );
        },
        validator: (newValue, row, column) => {
          if (reftypeofcomp.current == "quality") {
            if (newValue == '') {
              setisdisabled(true);
              row.BatchCode = ''
              toast.error('Batch Code is required', {
                position: "bottom-left",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return {
                valid: false,
                message: 'Batch Code is required'
              };
            }
          };


          if (reftypeofcomp.current !== "logistics") {

          if (newValue.length > 45) {
            setisdisabled(true);
            row.salesorder = ''
            toast.error('Batch Code can contain atmost 45 characters', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {

              valid: false,
              message: 'Batch Code can contain atmost 45 characters'
            };
          };




          if (newValue.length < 4) {
            setisdisabled(true);
            row.salesorder = ''
            toast.error('Batch Code must have atleast 4 characters', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {

              valid: false,
              message: 'Batch Code must have atleast 4 characters'
            };
          };
        }
          if (watch("typeofcomplaint") == "") {
            setisdisabled(true);
            row.BatchCode = ''
            toast.error('First fill the type of complaint', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'First fill the type of complaint'
            };
          };
          return true;
        }
      },
      {
        dataField: "Dateofarrival",
        text: "Date of Arrival",
        headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2px' }}>
              <a data-tip data-for='dateofarrival' style={{ textDecoration: "underline", color: "#0d6efd", fontWeight: "700" }}> Date Of Arrival </a>
              <Tooltip id='dateofarrival' aria-haspopup='true' effect="solid" >
                <p style={{ textAlign: 'left' }}>Arrival date to Warehouse</p>
                <p style={{ textAlign: 'left' }}>Note: Demurrage and Detention in POD: 20 business days from ATA.</p>
              </Tooltip>
            </div>
          );
        },
        formatter: (cell) => {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = new Date(cell);
          }
          return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
        },
        editor: {
          type: Type.DATE
        },
        validator: (newValue, row, column) => {
          var date1 = new Date(newValue);
          const todaymoment = moment();
          let now1 = new Date()
          console.log("Hello")
          console.log(now1.getUTCMonth() - date1.getUTCMonth())
          console.log(now1.getDate() - date1.getDate())
          if (newValue == '') {
            setisdisabled(true);
            row.Dateofarrival = ''
            toast.error('Date of Arrival is required', {
              position: "bottom-left",
              autoClose: 9000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            return {
              valid: false,
              message: 'Date of Arrival is required'
            };
          };
          if (watch("typeofcomplaint") == "logistics" || watch("typeofcomplaint") == "") {
            var isGreater1;
            if (todaymoment > businessmoment(newValue, 'YYYY-MM-DD').businessAdd(20)._d) {
              setisdisabled(true);
              row.Dateofarrival = ''
              toast.error('Date of Arrival is beyond 20 days so your complaint for this sales order cannot be accepted', {
                position: "bottom-left",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return {
                valid: false,
                message: 'Date of Arrival is beyond 20 Business days so your complaint for this sales order cannot be accepted'
              };
            };
          }
          if (reftypeofcomp.current == "quality") {
            var isGreater2;
            if (isGreater2 = +now1 > date1.setDate(date1.getDate() + 90)) {
              setisdisabled(true);
              row.Dateofarrival = ''
              toast.error('Date of Arrival is beyond 3 months so your complaint for this sales order cannot be accepted', {
                position: "bottom-left",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return {
                valid: false,
                message: 'Date of Arrival is beyond 3 months so your complaint for this sales order cannot be accepted'
              };
            };
          }
          return true;
        }
      },
      {
        dataField: "orderbasePkey",
        text: "",
        editable: false,
        formatter: (cell, row) => {
          if (row.id != 1)
            return (
              <Button
                className="btn-md btn-danger btn-xs border-secondary rounded"
                onClick={() => {
                  console.log(reforder.current)
                  setorder(order);
                  orders({
                    actionType: "deleteRow",
                    row: row.id,
                    salesorder: row.salesorder,
                    container: row.container,
                    POnumber: row.POnumber,
                    Qty: row.Qty,
                    SKU: row.SKU,
                    BatchCode: row.BatchCode,
                    Dateofarrival: row.Dateofarrival
                  })
                  console.log(reforder.current);
                  console.log(reforder.current.length)
                  var validation = false;
                  for (var i = 0; i < reforder.current.length; i++) {
                    if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || refdata1.current[i].cost == '' || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
                      setisvalid(false);
                      validation = false;
                      setisdisabled(true)
                      break;
                    }
                    else {
                      setisvalid(true);
                      validation = true;
                      console.log(validation);
                    }
                    if (reforder.current[i].BatchCode == '' && (reftypeofcomp.current == "quality") && reforder.current[i].dataverified.batchcodeverify == "false") {
                      setisvalid(false);
                      validation = false;
                      setisdisabled(true)
                      break;

                    }

                    if (reftypeofcomp.current == "quality" && reforder.current[i].BatchCode == '') {
                      setisvalid(false);
                      validation = false;
                      setisdisabled(true)
                      break;
                    }
                  }
                  if (reffcount.current > 0 && validation == true) {
                    setisdisabled(false)
                  }
                  else {
                    setisdisabled(true)
                  }
                }}

              >
                Delete
              </Button>
            );
          return null;
        }
      },
      {
        dataField: "dataverified",
        text: "Data Verified",
        editable: false,
        formatter: (cell, row) => {
          if (row.dataverified.salesorderverify == false) {
            return (<h4>SALESORDER INVALID</h4>);

          }
          if (row.dataverified.skuverify == false) {
            return (<h4>SKU INVALID</h4>);

          }
          if (row.dataverified.ponumberverify == "false") {
            return (<h4>PO NUMBER INVALID</h4>);

          }
          if (row.dataverified.containerverify == "false") {
            return (<h4>CONTAINER NUMBER INVALID</h4>);

          }
          if (row.dataverified.zxxvl == "false") {
            return (<h4>SALES ORDER AND SKU DOES NOT MATCH</h4>);

          }
          if (row.dataverified.batchcodeverify == "false") {
            return (<h4>BATCHCODE is not valid</h4>);

          }
          // if(row.dataverified.zcdp=="false"  ){
          //   return (<h4>Container and PO Number DOES NOT MATCH</h4>);

          // }
          if (row.dataverified.zxxvl == "not_checked") {
            return (<h4>SALES ORDER AND SKU DOES NOT MATCH</h4>);

          }
          if ((row.dataverified.salesorderverify === true) && (row.dataverified.skuverify === true) && (row.dataverified.zxxvl == "true")) {
            return (
              <Checkmark size='48px' />
            );
          }
          else
            return (

              <h4>Data NOT checked</h4>
            );

          // if (dataverifycheck?.salesorderverify==true){
          //   return (
          //     <Checkmark size='96px' />
          //   );
          //   }            
          //   else {
          //     console.log("from formatter",{dataverifycheck},row.orderbasevalidationekey?.salesorderverify);
          //   return (

          //    <div>
          //      <h1>hiii</h1></div> 

          //     )

          //   }


          return null;

        }
      }
    ];
    console.log("Disabled button", isdisabled)
    return (
      <div xs={12} className="col form">

        <ToolkitProvider
          keyField="id"
          order={tableData2}
          columns={columns2}
          exportCSV
        >
          {props => (
            <div>
              <div className="d-flex  ">
                {/* <ExportCSVButton justify-content-around p-2
                        className="export-button border-secondary rounded"
                        {...props.csvProps}
                      >
                        <span>Export to CSV</span>
                      </ExportCSVButton>
                      */}<table>
                  <tr><td>
                  </td>
                    <td className="float-right">
                      <Button
                        className="btn bg-success text-light rounded" style={{ alignItems: 'right', marginBottom: '20px', marginTop: '10px' }}

                        onClick={() => {
                          if (!tableData2) {
                            setorder(order);
                          }
                          setorder(tableData2);
                          orders({ actionType: "addRow" })
                          var validation = false;
                          console.log(reforder.current)
                          for (var i = 0; i < reforder.current.length; i++) {
                            if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || refdata1.current[i].cost == '' || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
                              setisvalid(false);
                              validation = false;
                              setisdisabled(true)
                              break;
                            }
                            else {
                              setisvalid(true);
                              validation = true;
                              console.log(validation);
                            }
                            if (reforder.current[i].BatchCode == '' && (reftypeofcomp.current == "quality") && reforder.current[i].dataverified.batchcodeverify == "false") {
                              setisvalid(false);
                              validation = false;
                              setisdisabled(true)
                              break;

                            }

                            if (reftypeofcomp.current == "quality" && reforder.current[i].BatchCode == '') {
                              setisvalid(false);
                              validation = false;
                              setisdisabled(true)
                              break;
                            }
                          }
                          if (reffcount.current > 0 && validation == true) {
                            setisdisabled(false)
                          }
                          else {
                            setisdisabled(true)
                          }

                        }}
                      >
                        ✚(insert new blank)
                      </Button></td></tr></table>
              </div>
              <span>Required fields: <b> Sales Order, QTY, SKU, Batch Code (if type of complaint is Quality), Date of Arrival</b></span><br />
              <span><b>Please ensure to select correct type of complaint before filling the table</b></span><br />
              <span><b>Please ensure to delete any empty rows before submitting a complaint</b></span>
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                data={tableData2}
                columns={columns2}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  onStartEdit: (row, column, rowIndex, columnIndex) => { setisdisabled(true); },
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    if (column.dataField === "container") {
                      if (isNaN(Number(newValue))) {
                        alert(
                          "You entered " +
                          newValue +
                          " Please Enter numbers Only!!"
                        );
                      }
                    }
                  },
                  onStartEdit: (row, column, rowIndex, columnIndex) => { console.log('start to edit!!!'); setisdisabled(true); setdropdisabled(true) },
                  beforeSaveCell: (oldValue, newValue, row, column) => { console.log(newValue) },
                  afterSaveCell: async (oldValue, newValue, row, column) => {
                    // setisdisabled(true)
                    setdropdisabled(false)
                    console.log(fcount)
                    if ((reftypeofcomp.current == "quality")) {
                      setisdisabled(true)
                      if (row.salesorder != '' && row.SKU != '' && row.Qty != '' && row.Dateofarrival != '' && row.BatchCode != '') {
                        setrowvalidating(true);
                        setisdisabled(true)
                        await AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/verifyautofillfromdatabase?salesorder=${row.salesorder}&container=${row.container}&POnumber=${row.POnumber}&SKU=${row.SKU}&BatchCode=${row.BatchCode}`).then(function (response) {
                          setrowvalidating(false)
                          var finaldata1 = response?.data
                          new Promise(function (resolve, reject) {
                            setisdisabled(true)
                            setTimeout(() => resolve(1), 1000); // (*)
                          }).then(function (result) {
                            return new Promise((resolve, reject) => {
                              setTimeout(() => resolve(result * 2), 3000);
                              console.log("checking value of data error")
                              console.log(finaldata1)
                              row.dataverified = (finaldata1);
                              let temp_state = [...order];
                              temp_state.map(a => a.id == row.id ? a.dataverified = row.dataverified : a.dataverified = a.dataverified)

                              setorder(temp_state);
                              console.log(row);
                            })
                          }).then(function (result) {
                            return new Promise((resolve, reject) => {
                              setTimeout(() => resolve(result * 2), 500);
                              console.log("Data 1:", refdata1.current)
                              //row.orderbasevalidationekey={dataverifycheck};
                              setisdisabled(true)
                              for (var i = 0; i < reforder.current.length; i++) {
                                setisdisabled(true)
                                if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || validateExtraFees() == false || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
                                  setisvalid(false);
                                  validation = false;
                                  setisdisabled(true)
                                  break;
                                }
                                else {
                                  setisvalid(true);
                                  validation = true;
                                  console.log(validation);
                                  if (reffcount.current > 0 && validation == true) {
                                    setisdisabled(false)
                                  }
                                  else {
                                    setisdisabled(true)
                                  }
                                }
                              }
                            })
                          })



                        })
                      }
                    }
                    else {
                      // setisdisabled(true)
                      // for(var i=0;i<reforder.current.length;i++){
                      //   setisdisabled(true)
                      //   if(reforder.current[i].salesorder=='' || reforder.current[i].SKU=='' || reforder.current[i].Qty=='' || reforder.current[i].Dateofarrival=='' || refdata1.current[i].cost == '' || reforder.current[i].dataverified.salesorderverify==false || reforder.current[i].dataverified.skuverify==false||reforder.current[i].dataverified.zxxvl=="false"){ //add filed here
                      //     setisvalid(false);
                      //     validation = false;
                      //     setisdisabled(true)
                      //     break;
                      //   }
                      //   else{
                      //     setisvalid(true);
                      //     validation = true;
                      //     console.log(validation);
                      //     if(reffcount.current > 0 && validation == true){
                      //       setisdisabled(false)
                      //     }
                      //     else{
                      //       setisdisabled(true)
                      //     }
                      //   }
                      // }

                      if (row.salesorder != '' && row.SKU != '' && row.Qty != '' && row.Dateofarrival != '') {
                        setrowvalidating(true);
                        setisdisabled(true)
                        await AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/verifyautofillfromdatabase?salesorder=${row.salesorder}&container=${row.container}&POnumber=${row.POnumber}&SKU=${row.SKU}&BatchCode=${row.BatchCode}`).then(function (response) {
                          setrowvalidating(false)
                          // setisdisabled(true)
                          var finaldata2 = response?.data
                          new Promise(function (resolve, reject) {
                            setisdisabled(true)
                            setTimeout(() => resolve(1), 1000); // (*)
                          }).then(function (result) {
                            return new Promise((resolve, reject) => {
                              setTimeout(() => resolve(result * 2), 3000);
                              console.log("checking value of data error")
                              console.log(finaldata2)
                              row.dataverified = (finaldata2);
                              let temp_state = [...order];
                              temp_state.map(a => a.id == row.id ? a.dataverified = row.dataverified : a.dataverified = a.dataverified)

                              setorder(temp_state);
                            })
                          }).then(function (result) {
                            return new Promise((resolve, reject) => {
                              setTimeout(() => resolve(result * 2), 500);
                              console.log(row);
                              //row.orderbasevalidationekey={dataverifycheck};
                              var validation
                              for (var i = 0; i < reforder.current.length; i++) {
                                if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || refdata1.current[i].cost == '' || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
                                  setisvalid(false);
                                  validation = false;
                                  setisdisabled(true)
                                  break;
                                }
                                else {
                                  setisvalid(true);
                                  validation = true;
                                  console.log(validation);
                                  if (reffcount.current > 0 && validation == true) {
                                    setisdisabled(false)
                                  }
                                  else {
                                    setisdisabled(true)
                                  }
                                }
                              }
                            })
                          })
                        })
                      }
                    }
                    var validation = false;


                  }
                })}
              >
              </BootstrapTable>
              <ToastContainer
                position="bottom-left"
                autoClose={9000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
  function RenderExpenseTable2(props) {

    function validateExtraFees() {
      for (var i = 0; i < refdata1.current.length; i++) {
        if (refdata1.current[i].cost == '') {
          return (false)
        }
        return (true)
      }
    }



    useEffect(() => {
      if (!data1.length) {
        setdata1([...props.prices({ action: "data" })]);
      }
    }, [])

    let tabledata1 = data1;
    if (JSON.stringify(props.data1) === JSON.stringify(tabledata1)) {
      console.log("in rendered table components the new data1 is: updated ");
    } else {
      console.log("in rendered table components the new data1 is: not updated ");
      tabledata1 = props.data1;
    }

    return (
      <div xs={12} className="col form">
        <ToolkitProvider
          keyField="id"
          data={data1}
          columns={columns}
          exportCSV
        >
          {props => (
            <div>
              <div className="d-flex justify-content-around p-2">
                <ExportCSVButton
                  className="export-button border-secondary rounded"
                  {...props.csvProps}
                >
                  <span>Export to CSV</span>
                </ExportCSVButton>


              </div>
              <BootstrapTable
                {...props.baseProps}
                keyField="id"
                data={tabledata1}
                columns={columns}
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  onStartEdit: (row, column, rowIndex, columnIndex) => { setisdisabled(true); setdropdisabled(true) },
                  beforeSaveCell: (oldValue, newValue, row, column) => { },
                  afterSaveCell: async (oldValue, newValue, row, column) => {
                    // setisdisabled(true)
                    setdropdisabled(false)
                    console.log(fcount)
                    var validation = false;
                    for (var i = 0; i < reforder.current.length; i++) {
                      if (reforder.current[i].salesorder == '' || reforder.current[i].SKU == '' || reforder.current[i].Qty == '' || reforder.current[i].Dateofarrival == '' || validateExtraFees() == false || reforder.current[i].dataverified.salesorderverify == false || reforder.current[i].dataverified.skuverify == false || reforder.current[i].dataverified.zxxvl == "false") { //add filed here
                        setisvalid(false);
                        validation = false;
                        setisdisabled(true)
                        break;
                      }
                      else {
                        setisvalid(true);
                        validation = true;
                        console.log(validation);
                      }
                      if (reforder.current[i].BatchCode == '' && (reftypeofcomp.current == "quality") && reforder.current[i].dataverified.batchcodeverify == "false") {
                        setisvalid(false);
                        validation = false;
                        setisdisabled(true)
                        break;

                      }

                      if (reftypeofcomp.current == "quality" && reforder.current[i].BatchCode == '') {
                        setisvalid(false);
                        validation = false;
                        setisdisabled(true)
                        break;
                      }
                    }
                    if (reffcount.current > 0 && validation == true) {
                      setisdisabled(false)
                    }
                    else {
                      setisdisabled(true)
                    }


                  }
                })}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default AddComplaint2;